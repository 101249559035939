@import "../../assets/sass/helper";

.root-slider {
  position: relative;

  &,
  .bg-image,
  .bg-image>.swiper {
    height: 100vh;
  }


  .hero-img.padding-image {
    @include media_991(min) {
      .swiper {
        width: 70%;
        left: 20%;
        top: 20%;
        height: 60%;
        margin: 0;
      }

      .swiper,
      .dsn-swiper-parallax-transform {
        height: calc(100% - 20%);
      }
    }
  }

  /**
    Hero Content
   */
  .hero-content {
    pointer-events: none;

    @include media_768(min) {
      padding-left: 12.5vw;
    }

    @include media_768() {
      padding: 0 var(--bs-gutter-x);
    }

    .dsn-description {
      max-width: 470px;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 13px;
    }

    .slide-content {
      position: absolute;


      .post-info,
      .dsn-description,
      .btn-default,
      .title-heading:not(.chars) {
        transition-property: opacity, visibility, transform, -webkit-transform;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }

      .dsn-description {
        transition-delay: 0.1s;
      }

      .btn-default {
        transition-delay: 0.2s;
      }

      &:not(.dsn-active) {

        .post-info,
        .dsn-description,
        .title-heading,
        .btn-default {
          opacity: 0;
          visibility: hidden;
        }

        .post-info {
          transform: translateY(20px);
        }

        .post-info,
        .dsn-description,
        .btn-default {
          transform: translateY(-20px);
        }

      }

      &.dsn-active {

        .post-info,
        .dsn-description,
        .btn-default,
        .title-heading {
          pointer-events: all;
        }
      }
    }
  }

  /**
    End Hero Content
   */


  .nav-slider {
    position: absolute;
    width: 8%;
    height: 30%;
    top: 35%;
    z-index: 3;
    overflow: hidden;

    .swiper {
      height: 100%;
    }

    @include media_768 {
      display: none;
    }

    .swiper-slide {
      .image-bg {
        position: relative;
        width: 30%;
        height: 100%;
        transition: width 1s;

        &:hover {
          width: 100%;
        }


      }

      .content {
        position: absolute;
        right: 0;
        top: -12px;
        z-index: 1;
        opacity: 0;
        transition: 0.5s;

        p {
          font-family: $heading-font;
          font-size: 22px;
          opacity: 0.8;
        }
      }

      &.swiper-slide-active {
        .image-bg {
          width: 100%;
        }

        .content {
          opacity: 0.5;
        }
      }
    }
  }


  .control-nav {
    position: absolute;
    bottom: 30px;
    z-index: 3;
    display: flex;

    @include media_575(min) {
      right: 50px;
    }

    @include media_575() {
      right: 30px;
    }

    svg {
      height: 25px;
      width: 25px;
    }

    .prev-container,
    .next-container {
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .prev-container {
      margin-right: 10px;
    }
  }

  .control-num {

    @include media_768(min) {
      position: absolute;
      bottom: 70px;
      z-index: 3;
      line-height: 1.1;
      right: 50px;

      span {
        font-size: 11vw;
        color: rgba(255, 255, 255, 0.03);
        -webkit-text-stroke: 1px rgba(255, 255, 255, 0.11);
        transform: scale3d(1, 1.15, 1.15);
      }
    }

    @include media_768 {
      display: none;
    }


  }
}